import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/insuranceRemits";

export async function searchInsuranceRemits(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function downloadPdf(insuranceRemit) {
  return await ApiService.query(url + "/getFile?id=" + insuranceRemit.id, {
    responseType: "blob",
  }).then((res) => {
    const fileURL = window.URL.createObjectURL(new Blob([res.data]));
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    //TODO temp
    fileLink.setAttribute("download", insuranceRemit.initFileName);
    document.body.appendChild(fileLink);

    fileLink.click();
  });
}
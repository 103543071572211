
import { defineComponent, onMounted, ref, reactive, nextTick } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import { getPanel } from "@/api/panel.api";
import { downloadPdf, searchInsuranceRemits } from "@/api/insurance-remits.api";

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  isLoaded: boolean;
  items: any[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  showInactive: boolean;
  modalPaymentId;
  modal;
}
export default defineComponent({
  name: "InsuranceRemitsList",
  components: { PaginationUi },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const orderList = [
      {
        name: "ERA Date",
        key: "eraDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Creation Date",
        key: "creationDate",
        isFilter: true,
        keyword: null,
      },
      {
        name: "Payer ID",
        key: "waystarPayerId",
        isFilter: true,
        keyword: null,
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      isLoaded: false,
      items: [],
      keyword: "",
      orderBy: "creationDate",
      IsAdvancedOrderBy: false,
      isDecr: true,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      panel: {},
      modalPaymentId: null,
      modal: false,
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "creationDate";
      data.IsAdvancedOrderBy = false;
      data.isDecr = true;

      await getAll();
    }

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Payment Management");

      await getAll();

      data.isLoaded = true;
    });

    async function selectFilter(header) {
      if (!header.key || !header.isFilter) {
        return;
      }
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function search() {
      await nextTick();
      data.pagination.currentPage = 1;
      await getAll();
    }

    async function getAll() {
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        globalSearch: data.keyword,
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
      };

      const res = await searchInsuranceRemits(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getNow() {
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      return date;
    }

    function addPaymentPlan() {
      router.push({ path: "paymentPlan" });
    }

    function selectPaymentPlan(item) {
      router.push({ path: "/paymentPlan/" + item.id });
    }

    function postPayment(installment) {
      router.push({
        path: "patientPayment",
        query: { type: 0, installmentId: installment.id },
      });
    }

    function goTo(initpath, value) {
      if (value) {
        const path = encodeURIComponent(route.fullPath);
        router.push({
          path: initpath + value,
          query: { breadcrumb: path },
        });
      }
    }

    async function download(insuranceRemit) {
      await downloadPdf(insuranceRemit);
    }

    return {
      organizationId,
      clearSearch,
      data,
      download,
      goTo,
      orderList,
      search,
      selectPaymentPlan,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
      postPayment,
      getNow,
      addPaymentPlan,
    };
  },
});
